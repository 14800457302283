<template>
  <v-dialog v-model="showDialog" max-width="1000" @click:outside="close">
    <v-card v-if="event">
      <v-card-title>
        <span>{{ event.title }}</span>
        <v-spacer></v-spacer>

        <span v-if="!isPinned">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click="pin" text v-on="on" :loading="pinIsLoading"><v-icon>push_pin</v-icon></v-btn>
            </template>
            <span>{{ $t("main.PIN_GMEVENT_ACTION") }}</span>
          </v-tooltip>
        </span>

        <span v-if="isPinned">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click="unpin" text v-on="on" :loading="pinIsLoading"><v-icon>done</v-icon></v-btn>
            </template>
            <span>{{ $t("main.UNPIN_GMEVENT_ACTION") }}</span>
          </v-tooltip>
        </span>

        <v-btn @click="close" text><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <div class="event-url">Url: <a :href="event.staticPageUrl" target="_blank">{{ event.staticPageUrl }}</a></div>
        <BosysDisplay :event="event" :bookingsEvent="bookingsEvent" :showDetails="showDetails" v-if="$hasPermission('a3m_core.DestinationManagerBosysBookings')"/>
        <iframe frameborder="0" :src="event.staticPageUrl" style="width: 100%; height: 70vh" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapActions, mapGetters } from "vuex";
import BosysDisplay from "../bosys/BosysDisplay.vue";

export default {
  name: 'EventDialog',
  components: {BosysDisplay},
  props: {
    show: {
      type: Boolean,
      required: true
    },
    event: {
      type: Object
    }
  },
  data() {
    return {
      showDialog: false,
      showDetails: false,
      pinIsLoading: false,
      bookingsEvent: null
    }
  },
  computed: {
    ...mapGetters({
      eventsPinnedGetById: 'eventsLatestSignificantUpdatesPinnedStore/getById',
    }),
    isPinned() {
      if (this.event) {
        let found = this.eventsPinnedGetById(this.event.id)
        if (found) {
          return true
        }
      }
      return false
    },
  },
  methods: {
    ...mapActions({
      eventsPinnedPin: 'eventsLatestSignificantUpdatesPinnedStore/pin',
      eventsPinnedUnpin: 'eventsLatestSignificantUpdatesPinnedStore/unpin',
      eventsLatestSignificantUpdatesPinnedFetchAll: 'eventsLatestSignificantUpdatesPinnedStore/fetchAll',
      bosysFetchAll: 'bosysStore/fetchAll',
    }),
    pin() {
      this.pinIsLoading = true
      this.eventsPinnedPin(this.event.id)
        .then(() => this.eventsLatestSignificantUpdatesPinnedFetchAll({force: true}))
        .then(() => this.pinIsLoading = false)
    },
    unpin() {
      this.pinIsLoading = true
      this.eventsPinnedUnpin(this.event.id)
        .then(() => this.eventsLatestSignificantUpdatesPinnedFetchAll({force: true}))
        .then(() => this.pinIsLoading = false)
    },
    close() {
      this.showDetails = false
      this.$emit('close')
    },
    fetchBookings(){
      this.bosysFetchAll({ variables: { gmUniqueEventId: this.event.gmUniqueEventId }, force: true })
        .then(response => {
          this.bookingsEvent = response;
        })
    }
  },
  watch: {
    show() {
      this.showDialog = this.show
      if (this.showDialog){
        this.fetchBookings()
      }
    }
  }

}
</script>

<style scoped>
  .event-url {
    margin-top: -15px;
  }
</style>
<template>
  <div>
    <v-subheader>
      <slot></slot>
    </v-subheader>
    <v-divider />
  </div>
</template>

<script>
export default {
  name: 'DateListHeaderHeader'
}
</script>

<style scoped>
  .news-header {
    color: #000000a1;
    font-weight: bold;
    border-bottom: 1px solid gray;
  }
</style>
<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      v-bind="attrs"
      type="article, article, article"
    />

    <v-list
      v-if="!loading"
      three-line
      class="pt-0"
    >
      <v-list-item-group v-model="selectedItem" :mandatory="selectedItem !== null">
        <template v-for="(item, index) in itemsByDate">
          <DateListHeader
            v-if="item.date && item.date != today"
            :key="index"
          >
            {{ item.date|i18n_dateToGerman }}
          </DateListHeader>
          <DateListHeader
            v-else-if="item.date && item.date == today"
            :key="index"
          >
            {{ $t('main.TODAY') }}
          </DateListHeader>
          <DateListItem
            v-else
            :key="index"
            :is-unread="item.isUnread"
            :is-new="item.isNew"
            :time="item.time"
            :title="item.title"
            :category="item.category || ''"
            :text="item.text"
            :icon="item.icon || ''"
            :category-icon="item.categoryIcon || ''"
            :max-length="maxLength"
            :allow-click="allowClick"
            :time-at-category="timeAtCategory"

            @click="select(item)"
          />
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import moment from 'moment'
import DateListItem from '@/components/ui/DateListItem';
import DateListHeader from '@/components/ui/DateListHeader';

export default {
  name: 'DateList',
  components: {
    DateListHeader,
    DateListItem
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    maxLength: {
      type: Number,
      default: 100
    },
    timeAtCategory: {
      type: Boolean,
      default: false
    },
    allowClick: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedItem: null
    }
  },
  computed: {
    itemsByDate() {
      let lastVisited = moment(this.lastVisited)

      let itemsByDate = []
      let lastDate = null
      for (let item of  this.items) {
        let newItem = {...item}
        newItem.time = moment(item.createdAt).format('HH:mm')

        const date = item.createdAt.split('T')[0];
        if (date !== lastDate) {
          itemsByDate.push({ date: date })
          lastDate = date
        }
        itemsByDate.push(newItem)
      }
      return itemsByDate
    },
    today() {
      return moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    select(item) {
      if (this.allowClick) {
        this.$emit("select", item)
      }
    }
  }
}
</script>

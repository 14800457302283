
const categoriesWithIcons = [
    "animalattack",
    "armedconflict",
    "cbrnalert",
    "climatechange",
    "coldwave",
    "complexemergency",
    "crime",
    "demonstration",
    "drought",
    "earthquake",
    "envpollution",
    "epidemic",
    "explosion",
    "flashflood",
    "flood",
    "freakwave",
    "hailstorm",
    "hazmataccident",
    "heatwave",
    "importantdate",
    "kidnapping",
    "landslide",
    "meteorite",
    "nocategory",
    "nuclearevent",
    "personalinjury",
    "pestoutbreak",
    "piracy",
    "poisoning",
    "politicalcrisis",
    "politicalsecurity",
    "poweroutage",
    "severeweather",
    "snowavalanche",
    "snowstorm",
    "snowsurge",
    "strike",
    "technicaldisaster",
    "terror",
    "thunderstorm",
    "tornado",
    "transportaccident",
    "tropicalcyclone",
    "tusnami",
    "urbanfire",
    "volcanicash",
    "volcanicactivity",
    "wildfire"
]

export default {

    getIconFileNameByCategorty(category, level) {
      if (categoriesWithIcons.find(element => element == category) === undefined) {
          return null
      }

      if (level === undefined || level < 0 || level > 5) {
          return null
      }

      let filename = 'event-' + category + '--' + level + '.png'
      return require(`../assets/event_icons/${filename}`)
    }

  }



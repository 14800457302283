<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      v-bind="attrs"
      type="article, article, article"
    />

    <v-list
      v-if="!loading"
      three-line
      class="pt-0"
    >
      <v-list-item-group v-model="selectedItem" :mandatory="selectedItem !== null">
        <template v-for="(item, index) in itemsByDate">
          <EventsHeader
            v-if="item.date && item.date != today"
            :key="index"
          >
            {{ item.date|i18n_dateToGerman }}
          </EventsHeader>
          <EventsHeader
            v-else-if="item.date && item.date == today"
            :key="index"
          >
            {{ $t('main.TODAY') }}
          </EventsHeader>
          <EventsEntry
            v-else
            :key="index"
            :is-unread="item.isUnread"
            :is-new="item.isNew"
            :time="item.time"
            :title="item.title"
            :category="item.category || ''"
            :text="item.text"
            :icon="item.icon || ''"
            :category-icon="item.categoryIcon || ''"
            :max-length="maxLength"
            :allow-click="allowClick"
            :time-at-category="timeAtCategory"
            :travelersNow="item.travelersNow"
            :travelersPotentiallyAffected="item.travelersPotentiallyAffected"

            @click="select(item)"
          />
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import moment from 'moment'
import EventsHeader from "./EventsHeader.vue";
import EventsEntry from "./EventsEntry.vue";

export default {
  name: 'EventItemsList',
  components: {
    EventsHeader,
    EventsEntry,
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    maxLength: {
      type: Number,
      default: 100
    },
    timeAtCategory: {
      type: Boolean,
      default: false
    },
    allowClick: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedItem: null
    }
  },
  computed: {
    itemsByDate() {
      let lastVisited = moment(this.lastVisited)

      let itemsByDate = []
      let lastDate = null
      for (let item of  this.items) {
        let newItem = {...item}
        newItem.time = moment(item.createdAt).format('HH:mm')

        const date = item.createdAt.split('T')[0];
        if (date !== lastDate) {
          itemsByDate.push({ date: date })
          lastDate = date
        }
        itemsByDate.push(newItem)
      }
      return itemsByDate
    },
    today() {
      return moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    select(item) {
      if (this.allowClick) {
        this.$emit("select", item)
      }
    }
  }
}
</script>

<template>
  <v-list-item
    :key="title"
    class="date-list-item"
    @click="clicked()"
  >
    <v-list-item-content>
      <v-list-item-title class="wrap-text">
        <slot name="category">
          <div class="category">
            <img v-if="categoryIcon" class="item-category-icon" :src="categoryIcon" />
            {{ category }} {{ title }}
            <span v-if="time && timeAtCategory" class="item-time">{{ time }}</span>
          </div>
        </slot>
      </v-list-item-title>

      <v-list-item-subtitle>
        <slot name="chip">
          <v-chip v-if="localIsUnread" x-small color="primary" class="item-chip"><v-icon small>markunread</v-icon></v-chip>
        </slot>
        <slot name="is-updated">
          <v-chip v-if="isUpdated" x-small color="accent" class="item-chip">{{ $t("main.UPDATE_ICON_TEXT") }}</v-chip>
        </slot>
        <slot name="is-new">
          <v-chip v-if="isNew" x-small color="secondary" class="item-chip">{{ $t("main.NEW_ICON_TEXT") }}</v-chip>
        </slot>
        <slot name="text">{{ textClean }}</slot>
        <span v-if="time && !timeAtCategory" class="item-time">{{ time }}</span>
      </v-list-item-subtitle>

    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'NewsEntry',
  props: {
    category: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    categoryIcon: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 100
    },
    isUnread: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    isUpdated: {
      type: Boolean,
      default: false
    },
    time: {
      tyoe: String,
      default: ''
    },
    timeAtCategory: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // The local version of isUnread is filled one time,
      // and then not changed any more. This helps us to keep the logic
      // of the "last visit" working, where we se the uiStore "lastNewsVisitDate"
      // based on the visit of the index page. Problem is: Thx to reactivity,
      // the change also hits "us" here, but we don't want to change the status until
      // the page is viewed again. So we have a local version, that does not change
      localIsUnread: null
    }
  },
  mounted() {
    this.processLocalIsUnread()
  },
  computed: {
    textClean() {
      // A version of the text without html, as required for the preview
      var div = document.createElement("div")
      div.innerHTML = this.text
      return div.textContent || div.innerText || ""
    },
    textTruncated() {
      if (this.isTextTruncated) {
        return this.textClean.slice(0, this.maxLength) + '...'
      }
      return this.textClean
    },
    isTextTruncated() {
      return this.textClean.length > this.maxLength
    }
  },
  methods: {
    clicked() {
      this.$emit('click')
    },
    processLocalIsUnread() {
      if (this.localIsUnread == null || this.isUnread) {
        this.localIsUnread = this.isUnread
      }
    },
  },
  watch: {
    isUnread() {
      this.processLocalIsUnread()
    }
  }
}
</script>

<style>
  .date-list-item.v-list-item {
    min-height: auto;
    min-height: auto;
  }
</style>

<style scoped>

  .wrap-text {
    white-space: normal;
  }

  .item-time {
    color: gray;
    float: right;
    font-size: 90%;
    font-weight: normal;
  }

  .item-icon,
  .item-category-icon {
    max-height: 20px;
    max-width: 32px;
    margin-right: 4px;
    vertical-align:middle;
  }

  .item-chip {
    margin-right: 0.5rem;
  }

  /*
  .news-entry {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .news-title {
    color: #000000a1;
    font-weight: bold;
  }

  .news-title .category {
    font-weight: normal;
    margin-right: 4px;
  }

  .news-text {
    color: #000000a1;
  }


  .news-read-more {
    margin-left: 0.5rem;
  }

  .news-is-clickable {
    cursor: pointer;
  }

  .news-is-clickable:hover .news-text,
  .news-is-clickable:hover .news-title {
    color: #1976d2;
  }
  */
</style>
<template>
  <div>
    <v-menu
      ref="menuDateRef"
      v-model="menuDate"
      :close-on-content-click="false"
      transition="scale-transition"
      :position-x="menuDateX"
      :position-y="menuDateY"
      absolute
      offset-y
      z-index="1001"
      min-width="290px"
    >
      <v-date-picker v-model="date" :min="min" :max="max"  first-day-of-week="1" no-title @input="menuDate = false"></v-date-picker>
    </v-menu>

    <v-text-field
      v-model="dateFormatted"
      ref="textField"
      :label="label"
      :append-icon="appendIcon"
      :dense="dense"
      :solo="solo"
      :clearable="clearable"
      :disabled="disabled"
      readonly
      @click="showDatePicker"
      @click:clear="cleared"
      @click:append="showDatePicker"
    ></v-text-field>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: 'DatePicker',
  props: {
    "value": {
      default: null,
    }, // v-model...
    "label": {
      type: String,
      default: "Date"
    },
    "appendIcon": {
      type: String,
      default: "event"
    },
    "min": null,
    "max": null,
    "dense": {
      type: Boolean
    },
    "solo": {
      type: Boolean
    },
    "clearable": {
      type: Boolean
    },
    "disabled": {
      type: Boolean
    },
    "readonly": {
      type: Boolean
    },
  },
  data() {
    return {
      date: null,
      menuDate: null,
      menuDateX: 0,
      menuDateY: 0
    }
  },
  computed: {
    dateFormatted: {
      get() {
        return this.formatDate(this.date)
      },
      set(value) {
        // called on clear, but we don't need it. Just here to prevent an error message
      }
    },
  },
  methods: {
    showDatePicker(event) {
      event.preventDefault()
      this.menuDate = false

      const rect = this.$refs.textField.$el.getBoundingClientRect()
      this.menuDateX = rect.left
      this.menuDateY = rect.top + rect.height

      this.$nextTick(() => {
        this.menuDate = true
      })
    },

    formatDate: function(date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },

    // clear button was clicked
    cleared() {
      this.date = null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.date = newVal
      },
    },
    date: {
      handler(newVal, oldVal) {
        this.$emit('input', newVal)
      },
    }
  }
}

</script>

<template>
  <div>
    <div style="height: 100%">
      <EventItemsList
        :items="items"
        :allow-click="true"
        :timeAtCategory="true"
        :loading="loading"
        @select="openEventPage"
      />
      <EventDialog :show="showEventDialog" :event="dialogEvent" @close="closeDialog"/>

      <div v-if="pinned && events.length == 0">
        <p>{{ $t("main.HOWTO_PIN_NOTICE") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions, mapGetters } from "vuex";
import EventService from '@/services/EventService';
import EventDialog from './EventDialog';
import EventItemsList from "./EventItemsList.vue";

export default {
  name: "EventList",
  components: {
    EventItemsList,
    EventDialog
  },
  props: {
    events: {
      type: Array,
      required: true
    },
    eventsLive: {
      type: Array,
      default: () => []
    },
    countryIds: {
      type: Array,
      required: true
    },
    pinned: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    liveEvents: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showEventDialog: false,
      dialogEvent: null,
    }
  },
  computed: {
    items() {
      let newsEvents = []
      let events = null
      if (this.liveEvents) {
        events = this.eventsLive
      } else {
        events = this.events
      }
      events.forEach(({ id, event, travelersNow, travelersPotentiallyAffected }) => {
        let newsEvent = {}

        let icon = EventService.getIconFileNameByCategorty(event.category.code, event.level)

        newsEvent.id = id
        newsEvent.text = event.title
        newsEvent.createdAt = event.significantUpdateAt
        newsEvent.category = event.category.name

        newsEvent.categoryIcon = icon

        newsEvent.isNew = event.countUpdates == 0

        newsEvent.staticPageUrl = event.staticPageUrl

        newsEvent.gmUniqueEventId = event.gmUniqueEventId

        newsEvent.travelersNow = travelersNow
        newsEvent.travelersPotentiallyAffected = travelersPotentiallyAffected

        newsEvents.push(newsEvent)
      })

      return newsEvents
    },
  },
  methods: {
    openEventPage(event) {
      this.dialogEvent = event
      this.showEventDialog = true
    },
    closeDialog() {
      this.showEventDialog=false
    },
  },
};
</script>